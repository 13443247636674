.tabs,
.room--finishes-desktop-nav,
.section--warranties-tab-nav {
    background: none;
    border: none;
    display: flex;
    text-transform: uppercase;

    .tabs-title {
        float: none;
        flex: 1;
    }

    .tabs-title:not(:last-child) {
        border-right: 1px solid $white;
    }

    a {
        color: $primary-contrast;
        text-decoration: none;
        padding-top: 28px;
        padding-bottom: 28px;
        font-size: rem-calc(14);
        line-height: 1.2;
        font-weight: 700;

        &:hover,
        &:focus,
        &:active {
            background: scale-color($primary, $lightness: $button-background-hover-lightness);
            color: $primary-contrast;
            text-decoration: underline;
        }

        &.is-active,
        &[aria-selected=true] {
            position: relative;
            background: $secondary;
            color: $secondary-contrast;

            &:before {
                position: absolute;
                content: '';
                bottom: -10px;
                left: 50%;
                margin-left: -14px;
                width: 0; 
                height: 0; 
                border-left: 14px solid transparent;
                border-right: 14px solid transparent;
                
                border-top: 10px solid $secondary;
            }

            &:hover,
            &:focus,
            &:active {
                background: $secondary;

                &:before {
                    border-top-color: $secondary;
                }
            }
        }
    }
}

.tabs-panel {
    padding: 0;
}

.tabs {
    @media print {
        display: none;
    }
}

.tabs-content {
    @media print {
        border: none;
    }
}