.banner--card-cta {
    @media print {
        margin-bottom: 1rem;
    }

    .banner--card-cta-bg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 80px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;

        @media print {
            display: none;
        }
    }

    .grid-container {
        @media screen {
            padding-left: 18px;
            padding-right: 18px;
        }

        & > .grid-padding-x {
            margin-left: -18px;
            margin-right: -18px;

            & > .cell {
                padding-left: 18px;
                padding-right: 18px;
            }
        }
    }

    .banner--card-cta-card {
        position: relative;
        max-width: 900px;
        margin: 0 auto;

        @media print {
            max-width: none;

            .card {
                border: none;
            }
        }
    }

    .card-section {
        padding: rem-calc(30) rem-calc(20);

        @include breakpoint(medium) {
            padding: rem-calc(70) rem-calc(80);
        }

        @media print {
            padding: 0;

            p {
                display: none;
            }
        }
    }

    .banner--card-cta-cell {
        position: relative;
        padding-top: 220px;

        @include breakpoint(small only) {
            padding-top: 120px;
        }

        @media print {
            padding-top: 50px;
        }
    }
}