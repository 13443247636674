.room--add-ons {
  margin-bottom: 100px;

  hr.room--add-ons-divider {
    height: 1px;
    background: #dcdce0;
  }

  @media print {
    margin-bottom: 0;

    h2 {
      font-size: 1.75rem;
    }

    .button {
      display: none;
    }
  }
}
