.room--tabs {

    .room--tabs-controls {
        background: $primary;
        text-transform: uppercase;
        font-size: rem-calc(15);
        line-height: 1.2;
        font-weight: 700;
    }
    
}