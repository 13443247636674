.section--product-card-links {
    padding-top: 39px;
    padding-bottom: 39px;
    background: $lighter-gray;

    @include breakpoint( medium ) {
        padding-top: 100px;
        padding-bottom: 59px;

        .slick-slide {
            padding: 0 23px 41px; /* bottom prevents overflow from cutting off shadow on hover */
        }

        .slick-initialized {
            margin: 0 -23px;
        }
    }

    .section-header {
        margin-bottom: 20px;

        @include breakpoint( medium ) {
            margin-bottom: 35px;
        }
    }

    @media print {
        padding-top: 1rem;

        .section-header {
            display: block;

            h2 {
                font-size: 1.5rem;
            }
        }

        .slick-track {
            width: 100% !important;
        }

        a.card--product-link {
            border: 1px solid #d1d2d3;

            .card-section {
                padding: 1rem;
            }
        }

        .slick-slide {
            width: 33.33% !important;
        }

        h3 {
            font-size: 1rem;
        }    

        .grid-x {
            justify-content: flex-start;
        }
    }    
}