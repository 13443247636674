.page-section {
    margin-bottom: rem-calc(32);

    @include breakpoint(medium) {
        margin-bottom: rem-calc(100);
    }

    &.page-section-no-margin {
        margin-bottom: 0;
    }
}

.section-header {
    margin-bottom: rem-calc(16);

    @include breakpoint(medium) {
        margin-bottom: rem-calc(64);
    }
}

.bg-primary {
    background-color: $primary;
    color: $primary-contrast;

    a {
        color: $primary-contrast;

        &:hover,
        &:focus,
        &:active {
            color: $primary-contrast;
        }
    }

    h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
        color: $primary-contrast;
    }
}

.align-wide {
    @media screen {
        max-width: 1500px;
    }
}

.align-wider {
    margin-left: 0;
    margin-right: 0;
    width: unset;
    max-width: unset;

    @include breakpoint( medium ) {
        margin-left: -50px;
        margin-right: -50px;
    }

    @include breakpoint( large ) {
        margin-left: -130px;
        margin-right: -130px;
    }
}

.tooltip {
    max-width: rem-calc(200);
    z-index: 9999999;
}

.tag {
    font-family: $header-font-family;
    font-size: rem-calc(12);
    line-height: 1.2;
    color: $dark-med-gray;
    text-transform: uppercase;
    font-weight: 700;

    &:not(:last-child) {
        padding-right: 9px;
        border-right: 1px solid $dark-med-gray;
        margin-right: 9px;
    }
}