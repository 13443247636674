.room--add-on-banner {
  padding-top: rem-calc(28);

  .room--add-on-play-button-container {
    width: 100%;
    height: 0;
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
  }

  .room--add-on-close-button {
    font-size: 36px;
    color: #FFF
  }

  .room--add-on-banner-embed {
    background: rgba(0,0,0,.75)
  }

  .room--add-on-banner-text {

    @include breakpoint(medium) {
      padding-left: rem-calc(15);
      padding-right: rem-calc(45);
    }
  }

  .button {
    margin-top: rem-calc(13);

    @include breakpoint(medium) {
      margin-top: rem-calc(22);
    }
  }

  &.room--add-on-banner-image-on-left {
    .room--add-on-banner-text {
      order: 2;

      @include breakpoint(medium) {
        padding-left: rem-calc(45);
        padding-right: rem-calc(15);
      }
    }
    .room--add-on-banner-media {
      order: 1;
    }
    .my-selections--template-add-ons-divider {
      order: 3;
    }
  }

  .room--add-on-banner-thumbnail {
    position: relative;
    display: inline-block;
    @media print {
      break-inside: avoid;
    }
  }

  .room--add-on-banner-thumbnail-icon {
    display: block;
    position: absolute;
    width: 160px;
    height: 160px;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
