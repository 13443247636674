.card--finish {
  position: relative;
  margin-bottom: 34px;

  &.cabinetry-cell {
    @include xy-cell(1 of 2, $gutters: rem-calc(30));

    @include breakpoint(medium) {
      @include xy-cell(1 of 5, $gutters: rem-calc(30));
    }
  }

  @media print {
    &.cabinetry-cell {
      @include xy-cell(1 of 4, $gutters: rem-calc(30));
      padding-bottom: 0;
    }
  }


  &.hardware-cell {
    @include xy-cell(1 of 2, $gutters: rem-calc(30));

    @include breakpoint(medium) {
      @include xy-cell(1 of 4, $gutters: rem-calc(30));
    }
  }

  .card--finish-image {
    margin-bottom: 18px;
  }

  .card--finish-name {
    font-family: $header-font-family;
    font-size: rem-calc(15);
    line-height: 1.2;
    font-weight: 700;
    color: $dark-med-gray;
    text-transform: uppercase;
    padding-right: 30px;
  }

  .card--finish-heart {
    position: absolute;
    text-decoration: none;
    display: inline-block;
    text-indent: -1000px;
    overflow: hidden;
    width: 20px;
    height: 18px;
    bottom: 2px;
    right: 0;
    border: none;
    cursor: pointer;

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: 'Add to selections';
      display: inline-block;
      background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAyMCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE3LjUzNCA5LjM0NDk0TDE3LjUzMzkgOS4zNDUxMkw5Ljk5ODY0IDE2LjczMzFMMi40NjQgOS4zNDU2OUMxLjc4MDYyIDguNjc0NTggMS4zMDkzMyA3LjgxNzU5IDEuMTA4NjEgNi44ODEwNUMwLjkwNzg4MyA1Ljk0NDUxIDAuOTg2NTYzIDQuOTY5NjQgMS4zMzQ4OCA0LjA3NzQyTDEuMzM0ODkgNC4wNzc0MkwxLjMzNjExIDQuMDc0MjVDMS42NDI5MiAzLjI4MDQxIDIuMTU0MTIgMi41ODE3OCAyLjgxNzkgMi4wNDkxNUMzLjQ3OTU0IDEuNTE4MjMgNC4yNjkzNSAxLjE3MTA1IDUuMTA3NzYgMS4wNDI1QzYuNjExNDggMC44NDI2MDkgOC4wNTM1OCAxLjQyODEgOS4yMjgwNyAyLjg0ODA4TDkuOTk4ODUgMy43Nzk5N0wxMC43Njk0IDIuODQ3ODdDMTEuOTQ1MiAxLjQyNTUxIDEzLjM4NjIgMC44NDA3MTcgMTQuODgxMSAxLjAzNzE1QzE1Ljc3MTYgMS4xNzgxOSAxNi42MDU3IDEuNTYzMTEgMTcuMjkwOSAyLjE0OTMzQzE3Ljk3ODggMi43Mzc5IDE4LjQ4OTUgMy41MDYyMSAxOC43NjU5IDQuMzY4MzZDMTkuMDQyMyA1LjIzMDUyIDE5LjA3MzQgNi4xNTI1NSAxOC44NTU5IDcuMDMxMzlDMTguNjM4MyA3LjkxMDI0IDE4LjE4MDcgOC43MTEyNiAxNy41MzQgOS4zNDQ5NFoiIHN0cm9rZT0iIzFBN0RDOSIgc3Ryb2tlLXdpZHRoPSIyIi8+CjxwYXRoIGQ9Ik0xNy41MzQgOS4zNDQ5NEwxNy41MzM5IDkuMzQ1MTJMOS45OTg2NCAxNi43MzMxTDIuNDY0IDkuMzQ1NjlDMS43ODA2MiA4LjY3NDU4IDEuMzA5MzMgNy44MTc1OSAxLjEwODYxIDYuODgxMDVDMC45MDc4ODMgNS45NDQ1MSAwLjk4NjU2MyA0Ljk2OTY0IDEuMzM0ODggNC4wNzc0MkwxLjMzNDg5IDQuMDc3NDJMMS4zMzYxMSA0LjA3NDI1QzEuNjQyOTIgMy4yODA0MSAyLjE1NDEyIDIuNTgxNzggMi44MTc5IDIuMDQ5MTVDMy40Nzk1NCAxLjUxODIzIDQuMjY5MzUgMS4xNzEwNSA1LjEwNzc2IDEuMDQyNUM2LjYxMTQ4IDAuODQyNjA5IDguMDUzNTggMS40MjgxIDkuMjI4MDcgMi44NDgwOEw5Ljk5ODg1IDMuNzc5OTdMMTAuNzY5NCAyLjg0Nzg3QzExLjk0NTIgMS40MjU1MSAxMy4zODYyIDAuODQwNzE3IDE0Ljg4MTEgMS4wMzcxNUMxNS43NzE2IDEuMTc4MTkgMTYuNjA1NyAxLjU2MzExIDE3LjI5MDkgMi4xNDkzM0MxNy45Nzg4IDIuNzM3OSAxOC40ODk1IDMuNTA2MjEgMTguNzY1OSA0LjM2ODM2QzE5LjA0MjMgNS4yMzA1MiAxOS4wNzM0IDYuMTUyNTUgMTguODU1OSA3LjAzMTM5QzE4LjYzODMgNy45MTAyNCAxOC4xODA3IDguNzExMjYgMTcuNTM0IDkuMzQ0OTRaIiBzdHJva2U9IiNDRENGRDQiIHN0cm9rZS13aWR0aD0iMiIvPgo8L3N2Zz4K") no-repeat center center;
    }

    &[aria-selected="true"]:before {
      content: 'Remove from selections';
      background: $secondary;
      -webkit-mask-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxOCAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE2LjIwOCA4Ljk0MTQ5TDkuMDk1MTYgMTUuOTE1M0M5LjAzOTc1IDE1Ljk2OTYgOC45NjUyNiAxNiA4Ljg4NzY4IDE2QzguODEwMSAxNiA4LjczNTYxIDE1Ljk2OTYgOC42ODAyMSAxNS45MTUzTDEuNTY3NDEgOC45NDE0OUMwLjgzNTc1OCA4LjIyMjk4IDAuMzMxMTc5IDcuMzA1NDYgMC4xMTYyNzggNi4zMDI3N0MtMC4wOTg2MjMxIDUuMzAwMDggLTAuMDE0Mzg2MiA0LjI1NjM2IDAuMzU4NTM3IDMuMzAxMTFDMC42ODc3NDEgMi40NDkzMyAxLjIzNjI1IDEuNjk5NjkgMS45NDg0OSAxLjEyODE4QzIuNjYwNzMgMC41NTY2NiAzLjUxMTM5IDAuMTgzNTcgNC40MTQyNyAwLjA0NjcxMDVDNi4xMDA2IC0wLjE4MDU5NCA3LjY3NTQzIDAuNDk5NDUyIDguODg3NjggMS45NjUwOUMxMC4xMDEyIDAuNDk3MTQgMTEuNjc1NSAtMC4xODI3MjggMTMuMzU1MiAwLjA0MjE0NkMxNC4zMTMyIDAuMTkxNjkzIDE1LjIxMDggMC42MDQ3MyAxNS45NDc1IDEuMjM1MDlDMTYuNjg0MyAxLjg2NTQ1IDE3LjIzMTMgMi42ODgyOSAxNy41MjczIDMuNjExNjVDMTcuODIzMyA0LjUzNSAxNy44NTY2IDUuNTIyNDggMTcuNjIzNiA2LjQ2MzcxQzE3LjM5MDcgNy40MDQ5NCAxNi45MDA1IDguMjYyODMgMTYuMjA4IDguOTQxNDlaIiBmaWxsPSIjMUE3REM5Ii8+Cjwvc3ZnPgo=");
      mask-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxOCAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE2LjIwOCA4Ljk0MTQ5TDkuMDk1MTYgMTUuOTE1M0M5LjAzOTc1IDE1Ljk2OTYgOC45NjUyNiAxNiA4Ljg4NzY4IDE2QzguODEwMSAxNiA4LjczNTYxIDE1Ljk2OTYgOC42ODAyMSAxNS45MTUzTDEuNTY3NDEgOC45NDE0OUMwLjgzNTc1OCA4LjIyMjk4IDAuMzMxMTc5IDcuMzA1NDYgMC4xMTYyNzggNi4zMDI3N0MtMC4wOTg2MjMxIDUuMzAwMDggLTAuMDE0Mzg2MiA0LjI1NjM2IDAuMzU4NTM3IDMuMzAxMTFDMC42ODc3NDEgMi40NDkzMyAxLjIzNjI1IDEuNjk5NjkgMS45NDg0OSAxLjEyODE4QzIuNjYwNzMgMC41NTY2NiAzLjUxMTM5IDAuMTgzNTcgNC40MTQyNyAwLjA0NjcxMDVDNi4xMDA2IC0wLjE4MDU5NCA3LjY3NTQzIDAuNDk5NDUyIDguODg3NjggMS45NjUwOUMxMC4xMDEyIDAuNDk3MTQgMTEuNjc1NSAtMC4xODI3MjggMTMuMzU1MiAwLjA0MjE0NkMxNC4zMTMyIDAuMTkxNjkzIDE1LjIxMDggMC42MDQ3MyAxNS45NDc1IDEuMjM1MDlDMTYuNjg0MyAxLjg2NTQ1IDE3LjIzMTMgMi42ODgyOSAxNy41MjczIDMuNjExNjVDMTcuODIzMyA0LjUzNSAxNy44NTY2IDUuNTIyNDggMTcuNjIzNiA2LjQ2MzcxQzE3LjM5MDcgNy40MDQ5NCAxNi45MDA1IDguMjYyODMgMTYuMjA4IDguOTQxNDlaIiBmaWxsPSIjMUE3REM5Ii8+Cjwvc3ZnPgo=");
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
      -webkit-mask-position: center center;
      mask-position: center center;
    }
  }

  .card--finish-modal-toggle {
    text-decoration: none;

    .card--finish-image {
      position: relative;

      &:after {
        display: flex;
        content: 'View Product Details';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: $secondary;
        color: $secondary-contrast;
        align-items: center;
        justify-content: center;
        text-decoration: underline;
        text-align: center;
        font-family: $header-font-family;
        opacity: 0;
        transition: opacity 300ms ease;
      }
    }

    &:hover,
    &:focus,
    &:active {
      text-decoration: underline;

      .card--finish-image {
        box-shadow: 0px 6px 35px rgba(0, 0, 0, 0.2);

        &:after {
            opacity: .9;
        }
      }
    }
  }

}