.site-header {
    position: relative;
    z-index: 10002;
    padding: 14px 0;
    box-shadow: 0 7px 30px 0 rgba($black, .07);
    background: $white;

    @include breakpoint(medium) {
        padding: 23px 0;
    }

    .nav-primary-mobile,
    .nav-main-wrap {
        display: flex;
        justify-content: flex-end;
    }

    a {
        text-decoration: none;
    }

    .nav-primary-mobile {
        button {
            position: relative;
            cursor: pointer;
            display: block;
            margin-right: -10px;
            text-indent: -1000px;
            text-decoration: none;
            overflow: hidden;
            width: rem-calc(27 + 20);
            height: rem-calc(20 + 10);

            &:after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                width: rem-calc(27 + 20);
                height: rem-calc(20 + 10);
                background: $primary;
                -webkit-mask-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjciIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyNyAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTI0Ljk5OTkgMjAuMDAwM0gxLjY2NjY3QzAuNzQ2MTYyIDIwLjAwMDMgMCAxOS4yNTQxIDAgMTguMzMzN0MwIDE3LjQxMzIgMC43NDYxNjIgMTYuNjY3IDEuNjY2NjcgMTYuNjY3SDI1QzI1LjkyMDQgMTYuNjY3IDI2LjY2NjYgMTcuNDEzMiAyNi42NjY2IDE4LjMzMzdDMjYuNjY2NiAxOS4yNTQxIDI1LjkyMDQgMjAuMDAwMyAyNC45OTk5IDIwLjAwMDNaIiBmaWxsPSIjMDA0ODc2Ii8+CjxwYXRoIGQ9Ik0yNC45OTk5IDExLjY2NjNIMS42NjY2N0MwLjc0NjE2MiAxMS42NjYzIDAgMTAuOTIwMSAwIDkuOTk5NjhDMCA5LjA3OTIzIDAuNzQ2MTYyIDguMzMzMDEgMS42NjY2NyA4LjMzMzAxSDI1QzI1LjkyMDQgOC4zMzMwMSAyNi42NjY2IDkuMDc5MTcgMjYuNjY2NiA5Ljk5OTY4QzI2LjY2NjYgMTAuOTIwMiAyNS45MjA0IDExLjY2NjMgMjQuOTk5OSAxMS42NjYzWiIgZmlsbD0iIzAwNDg3NiIvPgo8cGF0aCBkPSJNMjQuOTk5OSAzLjMzMzM0SDEuNjY2NjdDMC43NDYxNjIgMy4zMzMzNCAwIDIuNTg3MTggMCAxLjY2NjY3QzAgMC43NDYxNjIgMC43NDYxNjIgMCAxLjY2NjY3IDBIMjVDMjUuOTIwNCAwIDI2LjY2NjYgMC43NDYxNjIgMjYuNjY2NiAxLjY2NjY3QzI2LjY2NjYgMi41ODcxOCAyNS45MjA0IDMuMzMzMzQgMjQuOTk5OSAzLjMzMzM0WiIgZmlsbD0iIzAwNDg3NiIvPgo8L3N2Zz4K);
                mask-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjciIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyNyAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTI0Ljk5OTkgMjAuMDAwM0gxLjY2NjY3QzAuNzQ2MTYyIDIwLjAwMDMgMCAxOS4yNTQxIDAgMTguMzMzN0MwIDE3LjQxMzIgMC43NDYxNjIgMTYuNjY3IDEuNjY2NjcgMTYuNjY3SDI1QzI1LjkyMDQgMTYuNjY3IDI2LjY2NjYgMTcuNDEzMiAyNi42NjY2IDE4LjMzMzdDMjYuNjY2NiAxOS4yNTQxIDI1LjkyMDQgMjAuMDAwMyAyNC45OTk5IDIwLjAwMDNaIiBmaWxsPSIjMDA0ODc2Ii8+CjxwYXRoIGQ9Ik0yNC45OTk5IDExLjY2NjNIMS42NjY2N0MwLjc0NjE2MiAxMS42NjYzIDAgMTAuOTIwMSAwIDkuOTk5NjhDMCA5LjA3OTIzIDAuNzQ2MTYyIDguMzMzMDEgMS42NjY2NyA4LjMzMzAxSDI1QzI1LjkyMDQgOC4zMzMwMSAyNi42NjY2IDkuMDc5MTcgMjYuNjY2NiA5Ljk5OTY4QzI2LjY2NjYgMTAuOTIwMiAyNS45MjA0IDExLjY2NjMgMjQuOTk5OSAxMS42NjYzWiIgZmlsbD0iIzAwNDg3NiIvPgo8cGF0aCBkPSJNMjQuOTk5OSAzLjMzMzM0SDEuNjY2NjdDMC43NDYxNjIgMy4zMzMzNCAwIDIuNTg3MTggMCAxLjY2NjY3QzAgMC43NDYxNjIgMC43NDYxNjIgMCAxLjY2NjY3IDBIMjVDMjUuOTIwNCAwIDI2LjY2NjYgMC43NDYxNjIgMjYuNjY2NiAxLjY2NjY3QzI2LjY2NjYgMi41ODcxOCAyNS45MjA0IDMuMzMzMzQgMjQuOTk5OSAzLjMzMzM0WiIgZmlsbD0iIzAwNDg3NiIvPgo8L3N2Zz4K);
                -webkit-mask-repeat: no-repeat;
                mask-repeat: no-repeat;
                -webkit-mask-position: center center;
                mask-position: center center;

                &:hover,
                &:focus,
                &:active {
                    background: $secondary;
                }
            }
        }

        button[aria-expanded="true"]:after {
            -webkit-mask-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjYiIGhlaWdodD0iMjYiIHZpZXdCb3g9IjAgMCAyNiAyNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wIDEzQzAgNS44MzE1MSA1LjgzMTUxIDAgMTMgMEMyMC4xNjg1IDAgMjYgNS44MzE1MSAyNiAxM0MyNiAyMC4xNjg1IDIwLjE2ODUgMjYgMTMgMjZDNS44MzE1MSAyNiAwIDIwLjE2ODUgMCAxM1pNMTcuNzg3NyAxNy43ODc4QzE4LjIxMTIgMTcuMzY0IDE4LjIxMTIgMTYuNjc5NSAxNy43ODc3IDE2LjI1NThMMTQuNTMyMiAxMy4wMDA0TDE3Ljc4NzcgOS43NDUwOEMxOC4yMTEyIDkuMzIxMzcgMTguMjExMiA4LjYzNjgxIDE3Ljc4NzcgOC4yMTMxMUMxNy4zNjQgNy43ODk2IDE2LjY3OTUgNy43ODk2IDE2LjI1NTggOC4yMTMxMUwxMy4wMDA0IDExLjQ2ODdMOS43NDUwNiA4LjIxMzExQzkuMzIxMzUgNy43ODk2IDguNjM2OCA3Ljc4OTYgOC4yMTMwOSA4LjIxMzExQzcuNzg5NTkgOC42MzY4MSA3Ljc4OTU5IDkuMzIxMzcgOC4yMTMwOSA5Ljc0NTA4TDExLjQ2ODYgMTMuMDAwNEw4LjIxMzA5IDE2LjI1NThDNy43ODk1OSAxNi42Nzk1IDcuNzg5NTkgMTcuMzY0IDguMjEzMDkgMTcuNzg3OEM4LjQyNDM1IDE3Ljk5OSA4LjcwMTY2IDE4LjEwNTEgOC45NzkxOCAxOC4xMDUxQzkuMjU2NDkgMTguMTA1MSA5LjUzMzggMTcuOTk5IDkuNzQ1MDYgMTcuNzg3OEwxMy4wMDA0IDE0LjUzMjJMMTYuMjU1OCAxNy43ODc4QzE2LjQ2NyAxNy45OTkgMTYuNzQ0MyAxOC4xMDUxIDE3LjAyMTcgMTguMTA1MUMxNy4yOTkyIDE4LjEwNTEgMTcuNTc2NSAxNy45OTkgMTcuNzg3NyAxNy43ODc4WiIgZmlsbD0iIzAwNDg3NiIvPgo8L3N2Zz4K);
            mask-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjYiIGhlaWdodD0iMjYiIHZpZXdCb3g9IjAgMCAyNiAyNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wIDEzQzAgNS44MzE1MSA1LjgzMTUxIDAgMTMgMEMyMC4xNjg1IDAgMjYgNS44MzE1MSAyNiAxM0MyNiAyMC4xNjg1IDIwLjE2ODUgMjYgMTMgMjZDNS44MzE1MSAyNiAwIDIwLjE2ODUgMCAxM1pNMTcuNzg3NyAxNy43ODc4QzE4LjIxMTIgMTcuMzY0IDE4LjIxMTIgMTYuNjc5NSAxNy43ODc3IDE2LjI1NThMMTQuNTMyMiAxMy4wMDA0TDE3Ljc4NzcgOS43NDUwOEMxOC4yMTEyIDkuMzIxMzcgMTguMjExMiA4LjYzNjgxIDE3Ljc4NzcgOC4yMTMxMUMxNy4zNjQgNy43ODk2IDE2LjY3OTUgNy43ODk2IDE2LjI1NTggOC4yMTMxMUwxMy4wMDA0IDExLjQ2ODdMOS43NDUwNiA4LjIxMzExQzkuMzIxMzUgNy43ODk2IDguNjM2OCA3Ljc4OTYgOC4yMTMwOSA4LjIxMzExQzcuNzg5NTkgOC42MzY4MSA3Ljc4OTU5IDkuMzIxMzcgOC4yMTMwOSA5Ljc0NTA4TDExLjQ2ODYgMTMuMDAwNEw4LjIxMzA5IDE2LjI1NThDNy43ODk1OSAxNi42Nzk1IDcuNzg5NTkgMTcuMzY0IDguMjEzMDkgMTcuNzg3OEM4LjQyNDM1IDE3Ljk5OSA4LjcwMTY2IDE4LjEwNTEgOC45NzkxOCAxOC4xMDUxQzkuMjU2NDkgMTguMTA1MSA5LjUzMzggMTcuOTk5IDkuNzQ1MDYgMTcuNzg3OEwxMy4wMDA0IDE0LjUzMjJMMTYuMjU1OCAxNy43ODc4QzE2LjQ2NyAxNy45OTkgMTYuNzQ0MyAxOC4xMDUxIDE3LjAyMTcgMTguMTA1MUMxNy4yOTkyIDE4LjEwNTEgMTcuNTc2NSAxNy45OTkgMTcuNzg3NyAxNy43ODc4WiIgZmlsbD0iIzAwNDg3NiIvPgo8L3N2Zz4K);
        }
    }

    .nav-main-wrap {
        ul[data-dropdown-menu] {
            & > li > a {
                color: $primary;
                font-family: $header-font-family;
                font-size: rem-calc(18);
                line-height: 1.2;
                border-bottom: 5px solid transparent;
                padding: 19px 10px 14px;
                margin-left: 60px;
            }

            & > li.is-dropdown-submenu-parent > a {
                padding-right: 31px;

                &:after {
                    width: 13px;
                    height: 8px;
                    background: $primary;
                    -webkit-mask-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEzIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik02LjE1NDc0IDcuODUyMDhMMC4xNDMxODEgMS42OTIxMUMtMC4wNDc3MjY5IDEuNDk0ODkgLTAuMDQ3NzI2OSAxLjE3NTk4IDAuMTQzMTgxIDAuOTc4NzU3TDAuOTQ3NDMgMC4xNDc5MTVDMS4xMzgzNCAtMC4wNDkzMDUxIDEuNDQ3MDQgLTAuMDQ5MzA1MSAxLjYzNzk1IDAuMTQ3OTE1TDYuNSA1LjEyMDM4TDExLjM2MjEgMC4xNDc5MTVDMTEuNTUzIC0wLjA0OTMwNTEgMTEuODYxNyAtMC4wNDkzMDUxIDEyLjA1MjYgMC4xNDc5MTVMMTIuODU2OCAwLjk3ODc1N0MxMy4wNDc3IDEuMTc1OTggMTMuMDQ3NyAxLjQ5NDg5IDEyLjg1NjggMS42OTIxMUw2Ljg0NTI2IDcuODUyMDhDNi42NTQzNSA4LjA0OTMgNi4zNDU2NSA4LjA0OTMgNi4xNTQ3NCA3Ljg1MjA4WiIgZmlsbD0iIzAwNDg3NiIvPgo8L3N2Zz4K);
                    mask-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEzIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik02LjE1NDc0IDcuODUyMDhMMC4xNDMxODEgMS42OTIxMUMtMC4wNDc3MjY5IDEuNDk0ODkgLTAuMDQ3NzI2OSAxLjE3NTk4IDAuMTQzMTgxIDAuOTc4NzU3TDAuOTQ3NDMgMC4xNDc5MTVDMS4xMzgzNCAtMC4wNDkzMDUxIDEuNDQ3MDQgLTAuMDQ5MzA1MSAxLjYzNzk1IDAuMTQ3OTE1TDYuNSA1LjEyMDM4TDExLjM2MjEgMC4xNDc5MTVDMTEuNTUzIC0wLjA0OTMwNTEgMTEuODYxNyAtMC4wNDkzMDUxIDEyLjA1MjYgMC4xNDc5MTVMMTIuODU2OCAwLjk3ODc1N0MxMy4wNDc3IDEuMTc1OTggMTMuMDQ3NyAxLjQ5NDg5IDEyLjg1NjggMS42OTIxMUw2Ljg0NTI2IDcuODUyMDhDNi42NTQzNSA4LjA0OTMgNi4zNDU2NSA4LjA0OTMgNi4xNTQ3NCA3Ljg1MjA4WiIgZmlsbD0iIzAwNDg3NiIvPgo8L3N2Zz4K);
                    border: none;
                    right: 0;
                    margin-top: -2px;
                }
            }

            & > li > a.nav-active,
            & > li > a.nav-active-parent {
                border-bottom-color: $secondary;
            }

            & > li > a:hover,
            & > li > a:focus,
            & > li > a:active,
            & > li > a.nav-active,
            & > li > a.nav-active-parent {
                color: $secondary;

                &:after {
                    background: $secondary;
                    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEzIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik02LjE1NDc0IDcuODUyMDhMMC4xNDMxODEgMS42OTIxMUMtMC4wNDc3MjY5IDEuNDk0ODkgLTAuMDQ3NzI2OSAxLjE3NTk4IDAuMTQzMTgxIDAuOTc4NzU3TDAuOTQ3NDMgMC4xNDc5MTVDMS4xMzgzNCAtMC4wNDkzMDUxIDEuNDQ3MDQgLTAuMDQ5MzA1MSAxLjYzNzk1IDAuMTQ3OTE1TDYuNSA1LjEyMDM4TDExLjM2MjEgMC4xNDc5MTVDMTEuNTUzIC0wLjA0OTMwNTEgMTEuODYxNyAtMC4wNDkzMDUxIDEyLjA1MjYgMC4xNDc5MTVMMTIuODU2OCAwLjk3ODc1N0MxMy4wNDc3IDEuMTc1OTggMTMuMDQ3NyAxLjQ5NDg5IDEyLjg1NjggMS42OTIxMUw2Ljg0NTI2IDcuODUyMDhDNi42NTQzNSA4LjA0OTMgNi4zNDU2NSA4LjA0OTMgNi4xNTQ3NCA3Ljg1MjA4WiIgZmlsbD0iIzAwNDg3NiIvPgo8cGF0aCBkPSJNNi4xNTQ3NCA3Ljg1MjA4TDAuMTQzMTgxIDEuNjkyMTFDLTAuMDQ3NzI2OSAxLjQ5NDg5IC0wLjA0NzcyNjkgMS4xNzU5OCAwLjE0MzE4MSAwLjk3ODc1N0wwLjk0NzQzIDAuMTQ3OTE1QzEuMTM4MzQgLTAuMDQ5MzA1MSAxLjQ0NzA0IC0wLjA0OTMwNTEgMS42Mzc5NSAwLjE0NzkxNUw2LjUgNS4xMjAzOEwxMS4zNjIxIDAuMTQ3OTE1QzExLjU1MyAtMC4wNDkzMDUxIDExLjg2MTcgLTAuMDQ5MzA1MSAxMi4wNTI2IDAuMTQ3OTE1TDEyLjg1NjggMC45Nzg3NTdDMTMuMDQ3NyAxLjE3NTk4IDEzLjA0NzcgMS40OTQ4OSAxMi44NTY4IDEuNjkyMTFMNi44NDUyNiA3Ljg1MjA4QzYuNjU0MzUgOC4wNDkzIDYuMzQ1NjUgOC4wNDkzIDYuMTU0NzQgNy44NTIwOFoiIGZpbGw9IiMxQTdEQzkiLz4KPC9zdmc+Cg==) !important;
                }
            }
        }

        ul, li {
            list-style: none;
        }

        ul ul {
            border: none;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            padding: 0 25px 30px;

            li {
                border-bottom: 1px solid $light-gray;
            }

            a {
                display: block;
                color: $primary;
                font-family: $header-font-family;
                font-size: rem-calc(18);
                line-height: 1.2;

                &:hover,
                &:focus,
                &:active,
                &.nav-active {
                    color: $secondary;
                }
            }
        }
    }

    .brand-cell {
        padding: 0 20px;
    }

    a.brand {
        display: block;
        max-width: 182px;
        height: 30px;
        
        & > img {
            object-fit: contain;
            height: 100%;
            width: auto;
        }

        @include breakpoint(medium) {
            max-width: 200px;
            height: 54px;
        }

        @include breakpoint(large) {
            max-width: 282px;
        }
    }
}

#dropdown-overlay {
    display: none;
    position: absolute;
    z-index: 10000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba($black, .75);
}

#dropdown-mobile-menu {
    z-index: 10001;
    padding: 0 10px;
    font-size: rem-calc(18);
    line-height: 1.2;
    font-family: $header-font-family;
    font-weight: 700;
    border: none;
    left: auto !important;
    right: 0 !important;

    ul,
    li {
        list-style: none;
        display: block;
        margin: 0;
        padding: 0;
    }

    li:not(:last-child) {
        border-bottom: 2px solid $light-gray;
    }

    a {
        display: block;
        text-decoration: none;
        padding: 23px 10px;
        outline-offset: 0;

        &:hover,
        &:focus,
        &:active {
            text-decoration: underline;
        }

        &.nav-active,
        &.nav-active-parent {
            color: $secondary;
        }
    }

    ul ul {
        background: $primary;
        color: $primary-contrast;
        font-family: $body-font-family;

        a {
            color: $primary-contrast;
            padding-left: 30px;
            padding-right: 30px;
    
            &:hover,
            &:focus,
            &:active {
                color: $primary-contrast;
            }

            &.nav-active,
            &.nav-active-parent {
                background: $secondary;
                color: $secondary-contrast;
            }
        }
    }

    .is-accordion-submenu-parent {
        & > a:after {
            display: none;
        }

        & > a:before {
            display: block;
            content: '';
            position: absolute;
            top: 50%;
            margin-top: -3px;
            right: 20px;
            width: 15px;
            height: 8px;
            background: $primary;
            -webkit-mask-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEzIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik02LjE1NDc0IDcuODUyMDhMMC4xNDMxODEgMS42OTIxMUMtMC4wNDc3MjY5IDEuNDk0ODkgLTAuMDQ3NzI2OSAxLjE3NTk4IDAuMTQzMTgxIDAuOTc4NzU3TDAuOTQ3NDMgMC4xNDc5MTVDMS4xMzgzNCAtMC4wNDkzMDUxIDEuNDQ3MDQgLTAuMDQ5MzA1MSAxLjYzNzk1IDAuMTQ3OTE1TDYuNSA1LjEyMDM4TDExLjM2MjEgMC4xNDc5MTVDMTEuNTUzIC0wLjA0OTMwNTEgMTEuODYxNyAtMC4wNDkzMDUxIDEyLjA1MjYgMC4xNDc5MTVMMTIuODU2OCAwLjk3ODc1N0MxMy4wNDc3IDEuMTc1OTggMTMuMDQ3NyAxLjQ5NDg5IDEyLjg1NjggMS42OTIxMUw2Ljg0NTI2IDcuODUyMDhDNi42NTQzNSA4LjA0OTMgNi4zNDU2NSA4LjA0OTMgNi4xNTQ3NCA3Ljg1MjA4WiIgZmlsbD0iIzAwNDg3NiIvPgo8L3N2Zz4K);
            mask-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEzIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik02LjE1NDc0IDcuODUyMDhMMC4xNDMxODEgMS42OTIxMUMtMC4wNDc3MjY5IDEuNDk0ODkgLTAuMDQ3NzI2OSAxLjE3NTk4IDAuMTQzMTgxIDAuOTc4NzU3TDAuOTQ3NDMgMC4xNDc5MTVDMS4xMzgzNCAtMC4wNDkzMDUxIDEuNDQ3MDQgLTAuMDQ5MzA1MSAxLjYzNzk1IDAuMTQ3OTE1TDYuNSA1LjEyMDM4TDExLjM2MjEgMC4xNDc5MTVDMTEuNTUzIC0wLjA0OTMwNTEgMTEuODYxNyAtMC4wNDkzMDUxIDEyLjA1MjYgMC4xNDc5MTVMMTIuODU2OCAwLjk3ODc1N0MxMy4wNDc3IDEuMTc1OTggMTMuMDQ3NyAxLjQ5NDg5IDEyLjg1NjggMS42OTIxMUw2Ljg0NTI2IDcuODUyMDhDNi42NTQzNSA4LjA0OTMgNi4zNDU2NSA4LjA0OTMgNi4xNTQ3NCA3Ljg1MjA4WiIgZmlsbD0iIzAwNDg3NiIvPgo8L3N2Zz4K);
            -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
            -webkit-mask-position: center center;
            mask-position: center center;
        }

        & > a.nav-active:after,
        & > a.nav-active-parent:after {
            background: $secondary;
        }

        &[aria-expanded="true"] > a:before {
            transform: rotate(180deg);
        }
    }

}