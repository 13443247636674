[data-selection-templates] {
  display: none;
}

.my-selections--template {

  .my-selections--template {
    margin-bottom: rem-calc(49);
  }

  .my-selections--template-product-line {
    margin-bottom: 20px;

    @media print {
      break-inside: avoid;
    }
  }

  .my-selections--template-finishes {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 36px;
  }
  .my-selections--template-add-ons {
    margin-bottom: 40px;

    & + .my-selections--template-more-button {
      margin-top: 0;

      .cell {
        text-align: center;
      }
    }

    @media print {
      break-inside: avoid;
    }
  }
  
  @media print {
    .heading-center-lines {
      text-align: left;
      padding: 0;
      margin-bottom: rem-calc(50);

      &:before {
        display: none;
      }

      .heading-center-lines-inner {
        position: static;
        margin: 0;
        padding: 0;
      }
    }
  }

  .my-selections--template-more-button {
    margin-top: -30px;
    margin-bottom: 80px;
  }

  .my-selections--empty {
    margin-bottom: 100px;
  }

}