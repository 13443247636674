.modal--product-line {
    margin-top: 20px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;

    @include breakpoint(medium) {
        margin-top: 0;
        width: 95%;
        max-width: 1440px;
        padding: 0;
    }

    .modal--product-line-content {
        padding: 60px 20px 20px;

        @include breakpoint(medium) {
            padding: 60px 40px 40px;
        }

        @include breakpoint(large) {
            padding: 80px 100px;
        }

        &.modal-hardware {
            @include breakpoint(large) {
                padding: 80px 50px;
            }
        }
    }

    .modal--product-line-shown {
        font-size: 14;
        line-height: 1.2;
        font-family: $header-font-family;
        font-style: italic;
    }

    .modal--product-line-main-image {
        margin-bottom: 30px;
    }

    .modal--product-line-levels {
        margin-top: 30px;
    }

    .card--finish-name {
        font-size: rem-calc(13);
        line-height: 1.2;
    }

    [data-line-template] {
        display: none;
    }

    .cabinetry-cell {
        @include xy-cell(1 of 2, $gutters: rem-calc(30));
    
        @include breakpoint(medium) {
            @include xy-cell(1 of 3, $gutters: rem-calc(30));
        }

        @include breakpoint(large) {
            @include xy-cell(1 of 4, $gutters: rem-calc(30));
        }

        .card--finish-image {
            position: relative;
            overflow: hidden;
            aspect-ratio: 1.328;

            img {
                position: absolute;
                bottom: 0;
                left: 0;
                max-width: none;
                width: 120%;
            }
        }
    }

    .cabinetry-left-col {
        @include xy-cell(1 of 1, $gutters: rem-calc(30));
        margin-bottom: 30px;

        @include breakpoint(medium) {
            @include xy-cell(4 of 12, $gutters: rem-calc(30));
            margin-bottom: 0;
        }
    }

    .cabinetry-right-col {
        @include xy-cell(1 of 1, $gutters: rem-calc(30));

        @include breakpoint(medium) {
            @include xy-cell(8 of 12, $gutters: rem-calc(30));
        }
    }
    
    .hardware-cell {
        @include xy-cell(1 of 2, $gutters: rem-calc(30));

        @include breakpoint(large) {
            @include xy-cell(1 of 3, $gutters: rem-calc(30));
        }
    }
    
    .hardware-left-col {
        @include xy-cell(1 of 1, $gutters: rem-calc(30));
        margin-bottom: 30px;

        @include breakpoint(medium) {
            @include xy-cell(6 of 12, $gutters: rem-calc(30));
            margin-bottom: 0;
        }
    }
    
    .hardware-right-col {
        @include xy-cell(1 of 1, $gutters: rem-calc(30));

        @include breakpoint(medium) {
            @include xy-cell(6 of 12, $gutters: rem-calc(30));
        }
    }

    .card--finish .card--finish-modal-toggle .card--finish-image:after {
        content: '';
    }

    .button {
        &:after {
            width: 20px;
            height: 20px;
            -webkit-mask-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wIDEwQzAgMTUuNTE0MyA0LjQ4NjQ1IDIwIDEwIDIwQzE1LjUxNDMgMjAgMjAgMTUuNTE0MyAyMCAxMEMyMCA0LjQ4NjQ1IDE1LjUxNDMgMCAxMCAwQzQuNDg2NDUgMCAwIDQuNDg2NDUgMCAxMFpNMTAuMDAwMiAxNC41Nzc5QzkuNTcyNjIgMTQuNTc3OSA5LjIyNTU5IDE0LjIzMTYgOS4yMjU1OSAxMy44MDMzVjEwLjcwNDlINi4xMjcxNUM1LjY5OTU3IDEwLjcwNDkgNS4zNTI1NCAxMC4zNTc4IDUuMzUyNTQgOS45MzAyNUM1LjM1MjU0IDkuNTAyNjcgNS42OTk1NyA5LjE1NTY0IDYuMTI3MTUgOS4xNTU2NEg5LjIyNTU5VjYuMDU3MzJDOS4yMjU1OSA1LjYyOTc1IDkuNTcyNjIgNS4yODI3MSAxMC4wMDAyIDUuMjgyNzFDMTAuNDI3OCA1LjI4MjcxIDEwLjc3NDggNS42Mjk3NSAxMC43NzQ4IDYuMDU3MzJWOS4xNTU2NEgxMy44NzMxQzE0LjMwMTQgOS4xNTU2NCAxNC42NDc3IDkuNTAyNjcgMTQuNjQ3NyA5LjkzMDI1QzE0LjY0NzcgMTAuMzU3OCAxNC4zMDE0IDEwLjcwNDkgMTMuODczMSAxMC43MDQ5SDEwLjc3NDhWMTMuODAzM0MxMC43NzQ4IDE0LjIzMTYgMTAuNDI3OCAxNC41Nzc5IDEwLjAwMDIgMTQuNTc3OVoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=");
            mask-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wIDEwQzAgMTUuNTE0MyA0LjQ4NjQ1IDIwIDEwIDIwQzE1LjUxNDMgMjAgMjAgMTUuNTE0MyAyMCAxMEMyMCA0LjQ4NjQ1IDE1LjUxNDMgMCAxMCAwQzQuNDg2NDUgMCAwIDQuNDg2NDUgMCAxMFpNMTAuMDAwMiAxNC41Nzc5QzkuNTcyNjIgMTQuNTc3OSA5LjIyNTU5IDE0LjIzMTYgOS4yMjU1OSAxMy44MDMzVjEwLjcwNDlINi4xMjcxNUM1LjY5OTU3IDEwLjcwNDkgNS4zNTI1NCAxMC4zNTc4IDUuMzUyNTQgOS45MzAyNUM1LjM1MjU0IDkuNTAyNjcgNS42OTk1NyA5LjE1NTY0IDYuMTI3MTUgOS4xNTU2NEg5LjIyNTU5VjYuMDU3MzJDOS4yMjU1OSA1LjYyOTc1IDkuNTcyNjIgNS4yODI3MSAxMC4wMDAyIDUuMjgyNzFDMTAuNDI3OCA1LjI4MjcxIDEwLjc3NDggNS42Mjk3NSAxMC43NzQ4IDYuMDU3MzJWOS4xNTU2NEgxMy44NzMxQzE0LjMwMTQgOS4xNTU2NCAxNC42NDc3IDkuNTAyNjcgMTQuNjQ3NyA5LjkzMDI1QzE0LjY0NzcgMTAuMzU3OCAxNC4zMDE0IDEwLjcwNDkgMTMuODczMSAxMC43MDQ5SDEwLjc3NDhWMTMuODAzM0MxMC43NzQ4IDE0LjIzMTYgMTAuNDI3OCAxNC41Nzc5IDEwLjAwMDIgMTQuNTc3OVoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=");
        }

        &[aria-selected=true]:after {
            -webkit-mask-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMCAyMEM0LjQ4NjQ1IDIwIDAgMTUuNTE0MyAwIDEwQzAgNC40ODY0NSA0LjQ4NjQ1IDAgMTAgMEMxNS41MTQzIDAgMjAgNC40ODY0NSAyMCAxMEMyMCAxNS41MTQzIDE1LjUxNDMgMjAgMTAgMjBaTTYuMTI3MTUgMTAuNzA0OUgxMy44NzMxQzE0LjMwMTQgMTAuNzA0OSAxNC42NDc3IDEwLjM1NzggMTQuNjQ3NyA5LjkzMDI1QzE0LjY0NzcgOS41MDI2NyAxNC4zMDE0IDkuMTU1NjQgMTMuODczMSA5LjE1NTY0SDYuMTI3MTVDNS42OTk1NyA5LjE1NTY0IDUuMzUyNTQgOS41MDI2NyA1LjM1MjU0IDkuOTMwMjVDNS4zNTI1NCAxMC4zNTc4IDUuNjk5NTcgMTAuNzA0OSA2LjEyNzE1IDEwLjcwNDlaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K");
            mask-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMCAyMEM0LjQ4NjQ1IDIwIDAgMTUuNTE0MyAwIDEwQzAgNC40ODY0NSA0LjQ4NjQ1IDAgMTAgMEMxNS41MTQzIDAgMjAgNC40ODY0NSAyMCAxMEMyMCAxNS41MTQzIDE1LjUxNDMgMjAgMTAgMjBaTTYuMTI3MTUgMTAuNzA0OUgxMy44NzMxQzE0LjMwMTQgMTAuNzA0OSAxNC42NDc3IDEwLjM1NzggMTQuNjQ3NyA5LjkzMDI1QzE0LjY0NzcgOS41MDI2NyAxNC4zMDE0IDkuMTU1NjQgMTMuODczMSA5LjE1NTY0SDYuMTI3MTVDNS42OTk1NyA5LjE1NTY0IDUuMzUyNTQgOS41MDI2NyA1LjM1MjU0IDkuOTMwMjVDNS4zNTI1NCAxMC4zNTc4IDUuNjk5NTcgMTAuNzA0OSA2LjEyNzE1IDEwLjcwNDlaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K");
        }

        &:hover,
        &:focus,
        &:active {
            &:after {
                left: 17px;
            }
        }
    }
}