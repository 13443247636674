.site-footer {
    text-transform: uppercase;
    color: $dark-med-gray;
    font-family: $header-font-family;
    padding: 35px 0;
    background: $white;

    @include breakpoint( medium ) {
        padding: 65px 0;
    }

    a {
        color: $secondary;
        text-decoration: none;

        &:hover,
        &:focus,
        &:active {
            color: $gray;
            text-decoration: underline;
        }
    }

    .brand {
        display: inline-block;
        max-width: rem-calc(180);
        height: rem-calc(50);
        
        & > img {
            object-fit: contain;
            height: 100%;
            width: auto;
        }

        @include breakpoint( medium ) {
            max-width: rem-calc(260);
            height: rem-calc(50);
        }
    }

    .site-footer-copyright {
        font-size: rem-calc(11);
        line-height: 1.2;
        margin-top: 20px;
        padding-top: 25px;
        border-top: 2px solid rgba($gray, .5);
        text-transform: initial;

        @include breakpoint( medium ) {
            font-size: rem-calc(13);
            line-height: 1.2;
            margin-top: 30px;
            padding-top: 40px;
        }
    }

    .nav-footer-wrap {
        font-size: rem-calc(12);
        line-height: 1.2;
        margin: 30px -15px -3px;

        @include breakpoint( medium ) {
            font-size: rem-calc(15);
            line-height: 1.2;
        }

        ul,
        li {
            display: inline-block;
            list-style: none;
            margin: 0;
            padding: 0;
        }

        a {
            display: inline-block;
            padding: 0 15px;
        }

        li:not(:first-child) {
            border-left: 1px solid $dark-med-gray;
        }
    }
}