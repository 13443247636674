.reveal {
    padding: rem-calc(30);
}

.reveal-overlay {
    z-index: 999999;
    background-color: rgba($black, .7);

    @media print {
        display: none !important;
    }

    .close-button {
        font-size: rem-calc(14);
        line-height: 1.2;
        text-transform: uppercase;
        font-family: $header-font-family;
        font-weight: 700;
        padding-right: rem-calc(21);
        color: $gray;
        top: 30px;
        right: 30px;

        &:after {
            position: absolute;
            top: 0;
            right: 0;
            content: '';
            display: block;
            width: rem-calc(14);
            height: rem-calc(14);
            background-color: $gray;
            -webkit-mask-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTkuMzAyNjQgNy4wNjU0OUM5LjI2NjU3IDcuMDI5MzggOS4yNjY1NyA2Ljk3MDYyIDkuMzAyNjQgNi45MzQ1MUwxMy43MSAyLjUyNTAyQzE0LjA4NjkgMi4xNDgwMSAxNC4wODY5IDEuNTM0NTMgMTMuNzEgMS4xNTc1TDEyLjgzNjMgMC4yODMzNDJDMTIuNjUzNiAwLjEwMDYwNiAxMi40MTA4IDAgMTIuMTUyNSAwQzExLjg5NDMgMCAxMS42NTE0IDAuMTAwNjA2IDExLjQ2ODggMC4yODMzMTVMNy4wNjE0NiA0LjY5Mjg3QzcuMDM5MTYgNC43MTUxNyA3LjAxMzcxIDQuNzE5ODcgNi45OTYzMyA0LjcxOTg3QzYuOTc4OTUgNC43MTk4NyA2Ljk1MzUgNC43MTUyIDYuOTMxMiA0LjY5Mjg3TDIuNTIzODMgMC4yODMzMTVDMi4zNDEyMSAwLjEwMDYwNiAyLjA5ODQgMCAxLjg0MDA5IDBDMS41ODE3OCAwIDEuMzM5IDAuMTAwNjA2IDEuMTU2MzcgMC4yODMzMTVMMC4yODI2NTIgMS4xNTc1Qy0wLjA5NDIxNzMgMS41MzQ1MyAtMC4wOTQyMTczIDIuMTQ4MDEgMC4yODI2NTIgMi41MjUwMkw0LjY4OTk5IDYuOTM0NTFDNC43MjYwNyA2Ljk3MDYyIDQuNzI2MDcgNy4wMjkzOCA0LjY4OTk5IDcuMDY1NDlMMC4yODI2NTIgMTEuNDc1Qy0wLjA5NDE5IDExLjg1MiAtMC4wOTQxOSAxMi40NjU1IDAuMjgyNjUyIDEyLjg0MjVMMS4xNTYzNyAxMy43MTY3QzEuMzM5IDEzLjg5OTQgMS41ODE4MSAxNCAxLjg0MDA5IDE0QzIuMDk4MzcgMTQgMi4zNDEyMSAxMy44OTk0IDIuNTIzODMgMTMuNzE2N0w2LjkzMTE3IDkuMzA3MTZDNi45NTM0NyA5LjI4NDgzIDYuOTc4ODkgOS4yODAxNiA2Ljk5NjMgOS4yODAxNkM3LjAxMzcxIDkuMjgwMTYgNy4wMzkxIDkuMjg0ODMgNy4wNjE0MyA5LjMwNzE5TDExLjQ2ODggMTMuNzE2N0MxMS42NTE0IDEzLjg5OTQgMTEuODk0MiAxNCAxMi4xNTI1IDE0QzEyLjQxMDggMTQgMTIuNjUzNiAxMy44OTk0IDEyLjgzNjIgMTMuNzE2N0wxMy43MSAxMi44NDI1QzE0LjA4NjggMTIuNDY1NSAxNC4wODY4IDExLjg1MiAxMy43MSAxMS40NzVMOS4zMDI2NCA3LjA2NTQ5WiIgZmlsbD0iIzhBOEE4QyIvPgo8L3N2Zz4K);
            mask-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTkuMzAyNjQgNy4wNjU0OUM5LjI2NjU3IDcuMDI5MzggOS4yNjY1NyA2Ljk3MDYyIDkuMzAyNjQgNi45MzQ1MUwxMy43MSAyLjUyNTAyQzE0LjA4NjkgMi4xNDgwMSAxNC4wODY5IDEuNTM0NTMgMTMuNzEgMS4xNTc1TDEyLjgzNjMgMC4yODMzNDJDMTIuNjUzNiAwLjEwMDYwNiAxMi40MTA4IDAgMTIuMTUyNSAwQzExLjg5NDMgMCAxMS42NTE0IDAuMTAwNjA2IDExLjQ2ODggMC4yODMzMTVMNy4wNjE0NiA0LjY5Mjg3QzcuMDM5MTYgNC43MTUxNyA3LjAxMzcxIDQuNzE5ODcgNi45OTYzMyA0LjcxOTg3QzYuOTc4OTUgNC43MTk4NyA2Ljk1MzUgNC43MTUyIDYuOTMxMiA0LjY5Mjg3TDIuNTIzODMgMC4yODMzMTVDMi4zNDEyMSAwLjEwMDYwNiAyLjA5ODQgMCAxLjg0MDA5IDBDMS41ODE3OCAwIDEuMzM5IDAuMTAwNjA2IDEuMTU2MzcgMC4yODMzMTVMMC4yODI2NTIgMS4xNTc1Qy0wLjA5NDIxNzMgMS41MzQ1MyAtMC4wOTQyMTczIDIuMTQ4MDEgMC4yODI2NTIgMi41MjUwMkw0LjY4OTk5IDYuOTM0NTFDNC43MjYwNyA2Ljk3MDYyIDQuNzI2MDcgNy4wMjkzOCA0LjY4OTk5IDcuMDY1NDlMMC4yODI2NTIgMTEuNDc1Qy0wLjA5NDE5IDExLjg1MiAtMC4wOTQxOSAxMi40NjU1IDAuMjgyNjUyIDEyLjg0MjVMMS4xNTYzNyAxMy43MTY3QzEuMzM5IDEzLjg5OTQgMS41ODE4MSAxNCAxLjg0MDA5IDE0QzIuMDk4MzcgMTQgMi4zNDEyMSAxMy44OTk0IDIuNTIzODMgMTMuNzE2N0w2LjkzMTE3IDkuMzA3MTZDNi45NTM0NyA5LjI4NDgzIDYuOTc4ODkgOS4yODAxNiA2Ljk5NjMgOS4yODAxNkM3LjAxMzcxIDkuMjgwMTYgNy4wMzkxIDkuMjg0ODMgNy4wNjE0MyA5LjMwNzE5TDExLjQ2ODggMTMuNzE2N0MxMS42NTE0IDEzLjg5OTQgMTEuODk0MiAxNCAxMi4xNTI1IDE0QzEyLjQxMDggMTQgMTIuNjUzNiAxMy44OTk0IDEyLjgzNjIgMTMuNzE2N0wxMy43MSAxMi44NDI1QzE0LjA4NjggMTIuNDY1NSAxNC4wODY4IDExLjg1MiAxMy43MSAxMS40NzVMOS4zMDI2NCA3LjA2NTQ5WiIgZmlsbD0iIzhBOEE4QyIvPgo8L3N2Zz4K);
            -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
            -webkit-mask-position: center center;
            mask-position: center center;
            -webkit-mask-size: contain;
            mask-size: contain;
        }
    }
}
