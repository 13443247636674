.accordion {
  font-size: rem-calc(20);
  line-height: 1.2;
  border-top: 1px solid $light-gray;

  .accordion-title {
    font-size: rem-calc(20);
    line-height: 1.2;
    text-decoration: none;
    font-family: $header-font-family;
    font-weight: 700;
    color: $primary;
    padding: 26px 55px 26px 0;
    border: none;
    border-bottom: 1px solid $light-gray;

    &:hover,
    &:focus,
    &:active {
      background: none;
      color: $secondary;
    }
  }

  .accordion-content {
    border: none;
    padding: 50px 20px;
  }
}