.banner--side-by-side {
    padding: rem-calc(42) 0 rem-calc(28);

    @include breakpoint(medium) {
        padding: rem-calc(100) 0;
    }

    @media print {
        display: none;
    }

    .banner--side-by-side-text {
        padding-right: rem-calc(15);

        @include breakpoint(medium) {
            padding-right: rem-calc(100);
        }
    }

    .button {
        margin-top: rem-calc(26);

        @include breakpoint(medium) {
            margin-top: rem-calc(44);
        }

        @media print {
            display: none;
        }
    }

    &.bg-none {
        padding-top: 0;
        padding-bottom: 0;
    }

    &.banner--side-by-side-image-on-left {
        .banner--side-by-side-text {
            order: 2;
            padding-right: rem-calc(15);

            @include breakpoint(medium) {
                padding-left: rem-calc(115);
            }
        }
        .banner--side-by-side-media {
            order: 1;
        }
    }
}