.carousel-nav {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    @include breakpoint( medium down ) {
        margin-top: 23px;
    }

    ul,
    li {
        list-style: none;
        margin: 0;
        padding: 0;
        display: inline-block;
    }

    ul {
        margin: -3px 20px 0;

        button {
            display: inline-block;
            text-indent: -1000px;
            overflow: hidden;
            width: rem-calc(8);
            height: rem-calc(8);
            border-radius: 999px;
            background: $secondary;
            cursor: pointer;
        }

        .slick-active button {
            background: $primary;
        }

        li:not(:last-child) {
            margin-right: 10px;
        }
    }

    .slick-arrow {
        text-indent: -1000px;
        overflow: hidden;
        width: rem-calc(13);
        height: rem-calc(20);
        cursor: pointer;
        background-color: $primary;
        -webkit-mask-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAxMyAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEwLjE5ODMgMEwxMyAyLjc0NzM0TDUuNjAzNTEgMTBMMTMgMTcuMjUyN0wxMC4xOTgzIDIwTC02LjA0MTk2ZS0wOCAxMEwxMC4xOTgzIDBaIiBmaWxsPSJ3aGl0ZSIvPgo8cGF0aCBkPSJNMTAuMTk4MyAwTDEzIDIuNzQ3MzRMNS42MDM1MSAxMEwxMyAxNy4yNTI3TDEwLjE5ODMgMjBMLTYuMDQxOTZlLTA4IDEwTDEwLjE5ODMgMFoiIGZpbGw9IiMwMDQ4NzYiLz4KPC9zdmc+Cg==);
        mask-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAxMyAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEwLjE5ODMgMEwxMyAyLjc0NzM0TDUuNjAzNTEgMTBMMTMgMTcuMjUyN0wxMC4xOTgzIDIwTC02LjA0MTk2ZS0wOCAxMEwxMC4xOTgzIDBaIiBmaWxsPSJ3aGl0ZSIvPgo8cGF0aCBkPSJNMTAuMTk4MyAwTDEzIDIuNzQ3MzRMNS42MDM1MSAxMEwxMyAxNy4yNTI3TDEwLjE5ODMgMjBMLTYuMDQxOTZlLTA4IDEwTDEwLjE5ODMgMFoiIGZpbGw9IiMwMDQ4NzYiLz4KPC9zdmc+Cg==);
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        -webkit-mask-position: center center;
        mask-position: center center;
        -webkit-mask-size: contain;
        mask-size: contain;
    }

    .slick-next {
        rotate: 180deg;
        order: 999
    }

}