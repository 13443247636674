$primary: #004876;
$primary-contrast: #FFF;
$secondary: #1A7DC9;
$secondary-contrast: #FFF;
$text-color: #707070;
$lighter-gray: #F2F4F5;
$light-gray: #D9D9D9;
$gray: #86898C;
$dark-med-gray: #666666;
$black: #1A1A1A;
$white: #FFFFFF;
$headline-font: "Domine";
$content-font: "Montserrat";