.section--image-grid {

    & > .grid-x {
        margin-bottom: rem-calc(-20);
    }

    .grid-padding-x > .cell {
        padding: 0 rem-calc(5);
        margin-bottom: rem-calc(10);

        @include breakpoint(medium) {
            padding: 0 rem-calc(10);
            margin-bottom: 0;
        }
    }

    a {
        position: relative;
        display: block;
        padding-bottom:100%;
        color: $white;
        font-size: 22px;
        line-height: 1.14;
        font-weight: 700;
        font-family: $header-font-family;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
        margin-bottom: rem-calc(20);

        @include breakpoint(medium) {
            font-size: 26px;
            line-height: 1.14;
        }

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 200%;
            background: linear-gradient(180deg, rgba($white, 0) 0%, $black 50%);
            transition: top 200ms ease;
        }

        span {
            position: absolute;
            display: block;
            left: 20px;
            bottom: 24px;
            right: 20px;

            &:after {
                content: '';
                display: inline-block;
                width: 17px;
                height: 17px;
                vertical-align: middle;
                background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAxNyAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEuMDkwOTcgOS42MTk1M0gxMy4yNzcxTDcuOTUzMTUgMTUuMDgyOEM3LjUyNzY4IDE1LjUxOTQgNy41Mjc2OCAxNi4yMzU5IDcuOTUzMTUgMTYuNjcyNUM4LjM3ODYzIDE3LjEwOTIgOS4wNjU5NCAxNy4xMDkyIDkuNDkxNDIgMTYuNjcyNUwxNi42ODA5IDkuMjk0ODZDMTcuMTA2NCA4Ljg1ODI1IDE3LjEwNjQgOC4xNTI5NSAxNi42ODA5IDcuNzE2MzNMOS41MDIzMyAwLjMyNzQ2MUM5LjA3Njg1IC0wLjEwOTE1NCA4LjM4OTU0IC0wLjEwOTE1NCA3Ljk2NDA2IDAuMzI3NDYxQzcuNTM4NTkgMC43NjQwNzYgNy41Mzg1OSAxLjQ2OTM4IDcuOTY0MDYgMS45MDU5OUwxMy4yNzcxIDcuMzgwNDdIMS4wOTA5N0MwLjQ5MDkzNSA3LjM4MDQ3IDAgNy44ODQyNiAwIDguNUMwIDkuMTE1NzQgMC40OTA5MzUgOS42MTk1MyAxLjA5MDk3IDkuNjE5NTNaIiBmaWxsPSIjMDAzQTUwIi8+CjxwYXRoIGQ9Ik0xLjA5MDk3IDkuNjE5NTNIMTMuMjc3MUw3Ljk1MzE1IDE1LjA4MjhDNy41Mjc2OCAxNS41MTk0IDcuNTI3NjggMTYuMjM1OSA3Ljk1MzE1IDE2LjY3MjVDOC4zNzg2MyAxNy4xMDkyIDkuMDY1OTQgMTcuMTA5MiA5LjQ5MTQyIDE2LjY3MjVMMTYuNjgwOSA5LjI5NDg2QzE3LjEwNjQgOC44NTgyNSAxNy4xMDY0IDguMTUyOTUgMTYuNjgwOSA3LjcxNjMzTDkuNTAyMzMgMC4zMjc0NjFDOS4wNzY4NSAtMC4xMDkxNTQgOC4zODk1NCAtMC4xMDkxNTQgNy45NjQwNiAwLjMyNzQ2MUM3LjUzODU5IDAuNzY0MDc2IDcuNTM4NTkgMS40NjkzOCA3Ljk2NDA2IDEuOTA1OTlMMTMuMjc3MSA3LjM4MDQ3SDEuMDkwOTdDMC40OTA5MzUgNy4zODA0NyAwIDcuODg0MjYgMCA4LjVDMCA5LjExNTc0IDAuNDkwOTM1IDkuNjE5NTMgMS4wOTA5NyA5LjYxOTUzWiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==) no-repeat center center;
                position: relative;
                left: 27px;
                margin-left: -17px; /* left and margin left to prevent icon only breaking to new line. */
                transition: left 200ms ease;
            }
        }

        &:hover {
            &:before {
                top: -25%
            }

            span:after {
                left: 32px;
            }
        }
    }
}