.section--warranties {
    .section--warranties-body {
        margin-top: 80px;
    }

    h3 {
        margin-top: rem-calc(50);
    }

    hr {
        margin-top: rem-calc(50);
        margin-bottom: rem-calc(50);
        border-bottom-color: $light-gray;

        @include breakpoint( medium ) {
            margin-top: rem-calc(75);
            margin-bottom: rem-calc(75);
        }
    
        @include breakpoint( large ) {
            margin-top: rem-calc(100);
            margin-bottom: rem-calc(100);
        }
    }
}