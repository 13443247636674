.card--product-link-wide {
    display: block;
    position: relative;
    text-decoration: none;
    background: $white;
    color: $dark-med-gray;
    margin-bottom: 54px;

    & > .grid-container {
        padding-left: 0;
        padding-right: 0;
    }

    .card--product-link-wide-media {
        background: no-repeat center center;
        background-size: cover;
        aspect-ratio: 1.94444;
        margin-bottom: 0;
        position: relative;

        @include breakpoint( medium ) {
            aspect-ratio: 1.4571428571;
            min-height: 350px;
        }

        @media print {
            aspect-ratio: initial;
            min-height: auto;
            padding: 0;
            position: static;
        }
    }

    .card--product-link-wide-content {
        padding: 40px;

        @include breakpoint( medium ) {
            padding: 50px 120px 50px 60px;
        }

        @include breakpoint( large ) {
            padding: 60px 170px 60px 80px;
        }

        @media print {
            padding: 1rem;
        }
    }

    .card--product-link-feature {
        position: absolute;
        max-width: 100px;
        right: 30px;
        bottom: -25px;

        @include breakpoint( medium ) {
            top: auto;
            bottom: -32px;
            left: -90px;
            right: auto;
            max-width: 150px;
        }

        @include breakpoint( large ) {
            max-width: 200px;
        }
        @media print {
            position: static;
        }
    }

    @media print {
        .card--product-link-wide-contain {
            border: 1px solid #d1d2d3;
            flex-wrap: nowrap;
            max-width: 50%;
            
            .button {
                display: none;
            }
        }
    }
}