.button {
    font-family: $header-font-family;
    padding: rem-calc(20) rem-calc(39+17) rem-calc(20) rem-calc(39);
    border-radius: 4px;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
    font-weight: 700;

    &:hover,
    &:focus,
    &:active {
        box-shadow: 0px 20px 45px rgba($black, 0.1);
    }

    &:after {
        content: '';
        display: inline-block;
        width: 10px;
        height: 10px;
        vertical-align: middle;
        position: relative;
        left: 17px;
        top: -1px;
        margin-left: -10px; /* left and margin left to prevent icon only breaking to new line. */
        transition: left 200ms ease;
        background-color: $primary-contrast;
        -webkit-mask-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAxNyAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEuMDkwOTcgOS42MTk1M0gxMy4yNzcxTDcuOTUzMTUgMTUuMDgyOEM3LjUyNzY4IDE1LjUxOTQgNy41Mjc2OCAxNi4yMzU5IDcuOTUzMTUgMTYuNjcyNUM4LjM3ODYzIDE3LjEwOTIgOS4wNjU5NCAxNy4xMDkyIDkuNDkxNDIgMTYuNjcyNUwxNi42ODA5IDkuMjk0ODZDMTcuMTA2NCA4Ljg1ODI1IDE3LjEwNjQgOC4xNTI5NSAxNi42ODA5IDcuNzE2MzNMOS41MDIzMyAwLjMyNzQ2MUM5LjA3Njg1IC0wLjEwOTE1NCA4LjM4OTU0IC0wLjEwOTE1NCA3Ljk2NDA2IDAuMzI3NDYxQzcuNTM4NTkgMC43NjQwNzYgNy41Mzg1OSAxLjQ2OTM4IDcuOTY0MDYgMS45MDU5OUwxMy4yNzcxIDcuMzgwNDdIMS4wOTA5N0MwLjQ5MDkzNSA3LjM4MDQ3IDAgNy44ODQyNiAwIDguNUMwIDkuMTE1NzQgMC40OTA5MzUgOS42MTk1MyAxLjA5MDk3IDkuNjE5NTNaIiBmaWxsPSIjMDAzQTUwIi8+CjxwYXRoIGQ9Ik0xLjA5MDk3IDkuNjE5NTNIMTMuMjc3MUw3Ljk1MzE1IDE1LjA4MjhDNy41Mjc2OCAxNS41MTk0IDcuNTI3NjggMTYuMjM1OSA3Ljk1MzE1IDE2LjY3MjVDOC4zNzg2MyAxNy4xMDkyIDkuMDY1OTQgMTcuMTA5MiA5LjQ5MTQyIDE2LjY3MjVMMTYuNjgwOSA5LjI5NDg2QzE3LjEwNjQgOC44NTgyNSAxNy4xMDY0IDguMTUyOTUgMTYuNjgwOSA3LjcxNjMzTDkuNTAyMzMgMC4zMjc0NjFDOS4wNzY4NSAtMC4xMDkxNTQgOC4zODk1NCAtMC4xMDkxNTQgNy45NjQwNiAwLjMyNzQ2MUM3LjUzODU5IDAuNzY0MDc2IDcuNTM4NTkgMS40NjkzOCA3Ljk2NDA2IDEuOTA1OTlMMTMuMjc3MSA3LjM4MDQ3SDEuMDkwOTdDMC40OTA5MzUgNy4zODA0NyAwIDcuODg0MjYgMCA4LjVDMCA5LjExNTc0IDAuNDkwOTM1IDkuNjE5NTMgMS4wOTA5NyA5LjYxOTUzWiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==);
        mask-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAxNyAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEuMDkwOTcgOS42MTk1M0gxMy4yNzcxTDcuOTUzMTUgMTUuMDgyOEM3LjUyNzY4IDE1LjUxOTQgNy41Mjc2OCAxNi4yMzU5IDcuOTUzMTUgMTYuNjcyNUM4LjM3ODYzIDE3LjEwOTIgOS4wNjU5NCAxNy4xMDkyIDkuNDkxNDIgMTYuNjcyNUwxNi42ODA5IDkuMjk0ODZDMTcuMTA2NCA4Ljg1ODI1IDE3LjEwNjQgOC4xNTI5NSAxNi42ODA5IDcuNzE2MzNMOS41MDIzMyAwLjMyNzQ2MUM5LjA3Njg1IC0wLjEwOTE1NCA4LjM4OTU0IC0wLjEwOTE1NCA3Ljk2NDA2IDAuMzI3NDYxQzcuNTM4NTkgMC43NjQwNzYgNy41Mzg1OSAxLjQ2OTM4IDcuOTY0MDYgMS45MDU5OUwxMy4yNzcxIDcuMzgwNDdIMS4wOTA5N0MwLjQ5MDkzNSA3LjM4MDQ3IDAgNy44ODQyNiAwIDguNUMwIDkuMTE1NzQgMC40OTA5MzUgOS42MTk1MyAxLjA5MDk3IDkuNjE5NTNaIiBmaWxsPSIjMDAzQTUwIi8+CjxwYXRoIGQ9Ik0xLjA5MDk3IDkuNjE5NTNIMTMuMjc3MUw3Ljk1MzE1IDE1LjA4MjhDNy41Mjc2OCAxNS41MTk0IDcuNTI3NjggMTYuMjM1OSA3Ljk1MzE1IDE2LjY3MjVDOC4zNzg2MyAxNy4xMDkyIDkuMDY1OTQgMTcuMTA5MiA5LjQ5MTQyIDE2LjY3MjVMMTYuNjgwOSA5LjI5NDg2QzE3LjEwNjQgOC44NTgyNSAxNy4xMDY0IDguMTUyOTUgMTYuNjgwOSA3LjcxNjMzTDkuNTAyMzMgMC4zMjc0NjFDOS4wNzY4NSAtMC4xMDkxNTQgOC4zODk1NCAtMC4xMDkxNTQgNy45NjQwNiAwLjMyNzQ2MUM3LjUzODU5IDAuNzY0MDc2IDcuNTM4NTkgMS40NjkzOCA3Ljk2NDA2IDEuOTA1OTlMMTMuMjc3MSA3LjM4MDQ3SDEuMDkwOTdDMC40OTA5MzUgNy4zODA0NyAwIDcuODg0MjYgMCA4LjVDMCA5LjExNTc0IDAuNDkwOTM1IDkuNjE5NTMgMS4wOTA5NyA5LjYxOTUzWiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==);
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        -webkit-mask-position: center center;
        mask-position: center center;
        -webkit-mask-size: contain;
        mask-size: contain;
    }

    &.secondary:after {
        background-color: $secondary-contrast;
    }

    &:hover,
    &:focus,
    &:active {
        &:after {
            left: 22px;
        }
    }

    &[aria-selected=true] {
        background: $secondary;
        color: $secondary-contrast;

        &.secondary:after {
            background-color: $secondary-contrast;
        }
    }
}

.bg-primary .button.secondary {
    &:hover,
    &:focus,
    &:active {
        background: $secondary-contrast;
        color: $secondary;

        &:after {
            background-color: $secondary;
        }
    }

    &[aria-selected=true] {
        background: $secondary;
        color: $secondary-contrast;

        &:after {
            background-color: $secondary-contrast;
        }
    }
}

.floating-selections {
    position: fixed;
    z-index: 999999;
    bottom: -50px;
    right: 10px;
    border: 1px solid $white;
    margin: 0;
    opacity: 0;
    $speed: 200ms;
    transition: opacity $speed ease-out, bottom $speed ease-out;

    &:after {
        width: 18px;
        height: 16px;
        background: $primary-contrast;
        -webkit-mask-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxOCAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE2LjIwOCA4Ljk0MTQ5TDkuMDk1MTYgMTUuOTE1M0M5LjAzOTc1IDE1Ljk2OTYgOC45NjUyNiAxNiA4Ljg4NzY4IDE2QzguODEwMSAxNiA4LjczNTYxIDE1Ljk2OTYgOC42ODAyMSAxNS45MTUzTDEuNTY3NDEgOC45NDE0OUMwLjgzNTc1OCA4LjIyMjk4IDAuMzMxMTc5IDcuMzA1NDYgMC4xMTYyNzggNi4zMDI3N0MtMC4wOTg2MjMxIDUuMzAwMDggLTAuMDE0Mzg2MiA0LjI1NjM2IDAuMzU4NTM3IDMuMzAxMTFDMC42ODc3NDEgMi40NDkzMyAxLjIzNjI1IDEuNjk5NjkgMS45NDg0OSAxLjEyODE4QzIuNjYwNzMgMC41NTY2NiAzLjUxMTM5IDAuMTgzNTcgNC40MTQyNyAwLjA0NjcxMDVDNi4xMDA2IC0wLjE4MDU5NCA3LjY3NTQzIDAuNDk5NDUyIDguODg3NjggMS45NjUwOUMxMC4xMDEyIDAuNDk3MTQgMTEuNjc1NSAtMC4xODI3MjggMTMuMzU1MiAwLjA0MjE0NkMxNC4zMTMyIDAuMTkxNjkzIDE1LjIxMDggMC42MDQ3MyAxNS45NDc1IDEuMjM1MDlDMTYuNjg0MyAxLjg2NTQ1IDE3LjIzMTMgMi42ODgyOSAxNy41MjczIDMuNjExNjVDMTcuODIzMyA0LjUzNSAxNy44NTY2IDUuNTIyNDggMTcuNjIzNiA2LjQ2MzcxQzE3LjM5MDcgNy40MDQ5NCAxNi45MDA1IDguMjYyODMgMTYuMjA4IDguOTQxNDlaIiBmaWxsPSIjMUE3REM5Ii8+Cjwvc3ZnPgo=");
        mask-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxOCAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE2LjIwOCA4Ljk0MTQ5TDkuMDk1MTYgMTUuOTE1M0M5LjAzOTc1IDE1Ljk2OTYgOC45NjUyNiAxNiA4Ljg4NzY4IDE2QzguODEwMSAxNiA4LjczNTYxIDE1Ljk2OTYgOC42ODAyMSAxNS45MTUzTDEuNTY3NDEgOC45NDE0OUMwLjgzNTc1OCA4LjIyMjk4IDAuMzMxMTc5IDcuMzA1NDYgMC4xMTYyNzggNi4zMDI3N0MtMC4wOTg2MjMxIDUuMzAwMDggLTAuMDE0Mzg2MiA0LjI1NjM2IDAuMzU4NTM3IDMuMzAxMTFDMC42ODc3NDEgMi40NDkzMyAxLjIzNjI1IDEuNjk5NjkgMS45NDg0OSAxLjEyODE4QzIuNjYwNzMgMC41NTY2NiAzLjUxMTM5IDAuMTgzNTcgNC40MTQyNyAwLjA0NjcxMDVDNi4xMDA2IC0wLjE4MDU5NCA3LjY3NTQzIDAuNDk5NDUyIDguODg3NjggMS45NjUwOUMxMC4xMDEyIDAuNDk3MTQgMTEuNjc1NSAtMC4xODI3MjggMTMuMzU1MiAwLjA0MjE0NkMxNC4zMTMyIDAuMTkxNjkzIDE1LjIxMDggMC42MDQ3MyAxNS45NDc1IDEuMjM1MDlDMTYuNjg0MyAxLjg2NTQ1IDE3LjIzMTMgMi42ODgyOSAxNy41MjczIDMuNjExNjVDMTcuODIzMyA0LjUzNSAxNy44NTY2IDUuNTIyNDggMTcuNjIzNiA2LjQ2MzcxQzE3LjM5MDcgNy40MDQ5NCAxNi45MDA1IDguMjYyODMgMTYuMjA4IDguOTQxNDlaIiBmaWxsPSIjMUE3REM5Ii8+Cjwvc3ZnPgo=");
    }

    &:hover:after,
    &:focus:after,
    &:active:after {
        @include breakpoint(small only) {
            left: 17px;
        }
        @include breakpoint(medium) {
            -webkit-mask-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAxNyAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEuMDkwOTcgOS42MTk1M0gxMy4yNzcxTDcuOTUzMTUgMTUuMDgyOEM3LjUyNzY4IDE1LjUxOTQgNy41Mjc2OCAxNi4yMzU5IDcuOTUzMTUgMTYuNjcyNUM4LjM3ODYzIDE3LjEwOTIgOS4wNjU5NCAxNy4xMDkyIDkuNDkxNDIgMTYuNjcyNUwxNi42ODA5IDkuMjk0ODZDMTcuMTA2NCA4Ljg1ODI1IDE3LjEwNjQgOC4xNTI5NSAxNi42ODA5IDcuNzE2MzNMOS41MDIzMyAwLjMyNzQ2MUM5LjA3Njg1IC0wLjEwOTE1NCA4LjM4OTU0IC0wLjEwOTE1NCA3Ljk2NDA2IDAuMzI3NDYxQzcuNTM4NTkgMC43NjQwNzYgNy41Mzg1OSAxLjQ2OTM4IDcuOTY0MDYgMS45MDU5OUwxMy4yNzcxIDcuMzgwNDdIMS4wOTA5N0MwLjQ5MDkzNSA3LjM4MDQ3IDAgNy44ODQyNiAwIDguNUMwIDkuMTE1NzQgMC40OTA5MzUgOS42MTk1MyAxLjA5MDk3IDkuNjE5NTNaIiBmaWxsPSIjMDAzQTUwIi8+CjxwYXRoIGQ9Ik0xLjA5MDk3IDkuNjE5NTNIMTMuMjc3MUw3Ljk1MzE1IDE1LjA4MjhDNy41Mjc2OCAxNS41MTk0IDcuNTI3NjggMTYuMjM1OSA3Ljk1MzE1IDE2LjY3MjVDOC4zNzg2MyAxNy4xMDkyIDkuMDY1OTQgMTcuMTA5MiA5LjQ5MTQyIDE2LjY3MjVMMTYuNjgwOSA5LjI5NDg2QzE3LjEwNjQgOC44NTgyNSAxNy4xMDY0IDguMTUyOTUgMTYuNjgwOSA3LjcxNjMzTDkuNTAyMzMgMC4zMjc0NjFDOS4wNzY4NSAtMC4xMDkxNTQgOC4zODk1NCAtMC4xMDkxNTQgNy45NjQwNiAwLjMyNzQ2MUM3LjUzODU5IDAuNzY0MDc2IDcuNTM4NTkgMS40NjkzOCA3Ljk2NDA2IDEuOTA1OTlMMTMuMjc3MSA3LjM4MDQ3SDEuMDkwOTdDMC40OTA5MzUgNy4zODA0NyAwIDcuODg0MjYgMCA4LjVDMCA5LjExNTc0IDAuNDkwOTM1IDkuNjE5NTMgMS4wOTA5NyA5LjYxOTUzWiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==);
            mask-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAxNyAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEuMDkwOTcgOS42MTk1M0gxMy4yNzcxTDcuOTUzMTUgMTUuMDgyOEM3LjUyNzY4IDE1LjUxOTQgNy41Mjc2OCAxNi4yMzU5IDcuOTUzMTUgMTYuNjcyNUM4LjM3ODYzIDE3LjEwOTIgOS4wNjU5NCAxNy4xMDkyIDkuNDkxNDIgMTYuNjcyNUwxNi42ODA5IDkuMjk0ODZDMTcuMTA2NCA4Ljg1ODI1IDE3LjEwNjQgOC4xNTI5NSAxNi42ODA5IDcuNzE2MzNMOS41MDIzMyAwLjMyNzQ2MUM5LjA3Njg1IC0wLjEwOTE1NCA4LjM4OTU0IC0wLjEwOTE1NCA3Ljk2NDA2IDAuMzI3NDYxQzcuNTM4NTkgMC43NjQwNzYgNy41Mzg1OSAxLjQ2OTM4IDcuOTY0MDYgMS45MDU5OUwxMy4yNzcxIDcuMzgwNDdIMS4wOTA5N0MwLjQ5MDkzNSA3LjM4MDQ3IDAgNy44ODQyNiAwIDguNUMwIDkuMTE1NzQgMC40OTA5MzUgOS42MTk1MyAxLjA5MDk3IDkuNjE5NTNaIiBmaWxsPSIjMDAzQTUwIi8+CjxwYXRoIGQ9Ik0xLjA5MDk3IDkuNjE5NTNIMTMuMjc3MUw3Ljk1MzE1IDE1LjA4MjhDNy41Mjc2OCAxNS41MTk0IDcuNTI3NjggMTYuMjM1OSA3Ljk1MzE1IDE2LjY3MjVDOC4zNzg2MyAxNy4xMDkyIDkuMDY1OTQgMTcuMTA5MiA5LjQ5MTQyIDE2LjY3MjVMMTYuNjgwOSA5LjI5NDg2QzE3LjEwNjQgOC44NTgyNSAxNy4xMDY0IDguMTUyOTUgMTYuNjgwOSA3LjcxNjMzTDkuNTAyMzMgMC4zMjc0NjFDOS4wNzY4NSAtMC4xMDkxNTQgOC4zODk1NCAtMC4xMDkxNTQgNy45NjQwNiAwLjMyNzQ2MUM3LjUzODU5IDAuNzY0MDc2IDcuNTM4NTkgMS40NjkzOCA3Ljk2NDA2IDEuOTA1OTlMMTMuMjc3MSA3LjM4MDQ3SDEuMDkwOTdDMC40OTA5MzUgNy4zODA0NyAwIDcuODg0MjYgMCA4LjVDMCA5LjExNTc0IDAuNDkwOTM1IDkuNjE5NTMgMS4wOTA5NyA5LjYxOTUzWiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==);
            -webkit-mask-size: 10px;
            mask-size: 10px;
        }
    }

    @at-root .show-floating-cta & {
        display: block;
        opacity: 1;
        bottom: 10px;

        @media print {
            display: none;
        }
    }

    @include breakpoint(small only) {
        padding: rem-calc(15) rem-calc(15+18) rem-calc(15) rem-calc(15);
        font-size: rem-calc(15);
        line-height: 1.2;

        &:after {
            width: 14px;
            height: 12px;
        }
    }
}