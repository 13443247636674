a.card--product-link {
    display: block;
    position: relative;
    text-decoration: none;
    background: $white;
    color: $dark-med-gray;
    font-family: $header-font-family;

    .card--product-link-media {
        display: block;
        position: relative;
        margin-bottom: 18px;

        & > img {
            display: block;
        }
    }

    .card--product-link-render {
        display: block;
        width: 100%;
        height: auto;
        max-height: 182px;
        overflow-y: hidden;
    }

    .card--product-link-feature {
        display: block;
        position: absolute;
        bottom: -25px;
        right: 30px;
        width: 100px;
        height: auto;
    }

    &.product-type-cabinetry .card--product-link-feature {
        max-width: 100px;
    }

    &.product-type-hardware .card--product-link-feature {
        max-width: 193px;
        bottom: -45px;
        right: 8px;
    }

    .card-section {
        display: block;
    }

    &:after {
        display: flex;
        content: 'View Now';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: $secondary;
        color: $secondary-contrast;
        align-items: center;
        justify-content: center;
        text-decoration: underline;
        font-family: $header-font-family;
        opacity: 0;
        transition: opacity 300ms ease;
    }

    &:hover,
    &:focus,
    &:active {
        box-shadow: 0px 6px 35px rgba(0, 0, 0, 0.2);

        &:after {
            opacity: .9;
        }
    }
}