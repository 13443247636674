//
// Foundation Settings
//
// Additional settings can be found in the foundation settings.scss file.
// *Do NOT* import the settings file, included bellow for quick reference only.
// @import '~foundation-sites/scss/settings/_settings.scss'
//

@import "~foundation-sites/scss/util/unit";
@import "~foundation-sites/scss/util/color";
@import "../_data/styles.scss";

$global-font-size: rem-calc(16);
$global-lineheight: 1.875;
$global-width: rem-calc(1300); // MUST be after $global-font-size for rem-calc

$foundation-palette: (
	primary: $primary,        /* Custom */
	secondary: $secondary,    /* Custom */
	success: #3adb76,       /* Default */
	warning: #ffae00,       /* Default */
	alert: #cc4b37,         /* Default */
);
$header-color: $primary;
@include add-foundation-colors;

$body-background: $white;
$body-font-color: $text-color;
$body-font-family: $content-font, Arial, sans-serif;
$global-weight-normal: 500;
$global-weight-bold: 700;

$header-font-family: $headline-font, serif;
$header-font-weight: $global-weight-bold;
$header-font-style: normal;
$header-lineheight: 1.175;
$header-margin-bottom: rem-calc(15);
$header-styles: (
	'small': (
		'h1': ('fs': 28, 'lh': $header-lineheight, 'mt': 0, 'mb': $header-margin-bottom),
		'h2': ('fs': 28, 'lh': $header-lineheight, 'mt': 0, 'mb': $header-margin-bottom),
		'h3': ('fs': 22, 'lh': $header-lineheight, 'mt': 0, 'mb': $header-margin-bottom),
		'h4': ('fs': 18, 'lh': $header-lineheight, 'mt': 0, 'mb': $header-margin-bottom),
		'h5': ('fs': 17, 'lh': $header-lineheight, 'mt': 0, 'mb': $header-margin-bottom),
		'h6': ('fs': 16, 'lh': $header-lineheight, 'mt': 0, 'mb': $header-margin-bottom)
	),
	'medium': (
		'h1': ('fs': 40, 'lh': $header-lineheight, 'mt': 0, 'mb': $header-margin-bottom),
		'h2': ('fs': 38, 'lh': $header-lineheight, 'mt': 0, 'mb': $header-margin-bottom),
		'h3': ('fs': 26, 'lh': $header-lineheight, 'mt': 0, 'mb': $header-margin-bottom),
		'h4': ('fs': 22, 'lh': $header-lineheight, 'mt': 0, 'mb': $header-margin-bottom),
		'h5': ('fs': 20, 'lh': $header-lineheight, 'mt': 0, 'mb': $header-margin-bottom),
		'h6': ('fs': 16, 'lh': $header-lineheight, 'mt': 0, 'mb': $header-margin-bottom)
	)
);

$card-shadow: 0 6px 30px 0 rgba(0, 0, 0, 0.08);
$card-padding: 22px 30px;

$offcanvas-shadow: none;
$offcanvas-inner-shadow-size: 0;
$offcanvas-background: $white;
$offcanvas-exit-background: rgba($black, 0.75);
$offcanvas-transition-length: 0.2s;
$offcanvas-sizes:  (
	small: 95%,
	medium: 350px,
  );

$grid-container-padding: (
	small: rem-calc(50),
	medium: rem-calc(90)
);