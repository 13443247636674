body {
    @include breakpoint(small only) {
        font-size: rem-calc(14);
        line-height: 1.6428;
    }
}

a:not(.button) {
    text-decoration: underline;

    &:hover,
    &:focus,
    &:active {
        text-decoration: none;
    }
}

a,
button,
input,
select,
textarea {
    &:focus {
        outline: 1px solid $secondary;
        outline-offset: 5px;
    }
}

p, ul, ol, li, dt, dl, dd {
    font-size: inherit;
    line-height: inherit;
}

.callout-text {
    font-weight: 700;
    color: $primary;
}

.heading-underline {
    padding-bottom: rem-calc(13);
    border-bottom: rem-calc(2) solid $light-gray;
}

.heading-center-lines {
    text-align: center;
    position: relative;
    margin-bottom: rem-calc(18);

    @include breakpoint( medium ) {
        padding-left: 25%;
        padding-right: 25%;
    }

    &:before {
        content: '';
        display: block;
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 0;
        right: 0;
        background: $light-gray;
        height: 2px;
        margin-top: -1px;
    }

    .heading-center-lines-inner {
        position: relative;
        z-index: 2;
        display: inline-block;
        background: $white;
        padding-left: 50px;
        padding-right: 50px;
    }
}

h1 {
    & + h2,
    & + .h2,
    & + h3,
    & + .h3,
    & + h4,
    & + .h4,
    & + h5,
    & + .h5,
    & + h6,
    & + .h6 {
        margin-top: rem-calc(-8);
    }
}

@media print {
    .print-hide-hrefs a:after {
            display: none;
    }

    .print-text-left {
        text-align: left;
    }
}